<template>
  <div>
    <div class="row ml-lg-5 mr-lg-5">
      <div class="col-lg-3 col-sm-12 h-100" v-for="invite in $store.getters.getTeamInvites()" :key="invite.id">
        <div class="card text-white h-100">
          <a @click="selectInvite(invite)" href="javascript:void(0)">
            <div class="card-body h-100">
              <div v-if="invite.expires_at" class="align-right">
                <div class="badge badge-primary font-size-12">
                  {{ formatExpiration(invite.expires_at) }}
                </div>
              </div>
              <div class="row justify-content-center" style="margin-top: 42px">
                <div class="col text-center">
                  <h3 class="pb-0 mt-4 mb-3 text-truncate">
                    {{ invite.team.identifier }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row text-center">
                <div class="col">
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('invites.members') }}
                  </p>
                  <h5 class="mb-0">
                    {{ $n(invite.team.memberships) }}
                  </h5>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconThreeDots } from "bootstrap-vue";
import humanizeDuration from "humanize-duration";
import { get_acsrf_token } from "@/methods";
import eventHandler from "@/state/events";
import {mapGetters} from "vuex";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BIconThreeDots
  },
  computed: {
    ...mapGetters(['getDTLocale'])
  },
  methods: {
    async selectInvite(invite) {
      const result = await this.$swal({
        icon: 'question',
        title: this.$t('invites.join.title', { name: invite.team.identifier }),
        showCloseButton: true,
        showDenyButton: true,
        confirmButtonText: this.$t('invites.join.accept'),
        denyButtonText: this.$t('invites.join.decline')
      });
      if (result.isDismissed) {
        return;
      }
      const acsrf_token = await get_acsrf_token();
      const payload = {
        acsrf_token: acsrf_token,
        state: result.isConfirmed
      };
      const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/invites/${invite.id}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include'
      });
      if (!response.ok) {
        return this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$store.commit('removeTeamInvite', invite);
      if(result.isConfirmed) {
        invite.team.memberships += 1;
        this.$store.commit('addTeam', invite.team);
      }
      eventHandler.$emit('reload-navigation')
      // await this.$router.push({name: 'team', params: {team_id: invite.team.id}});
    },
    formatExpiration(date) {
      return this.$t('invites.expires',
          { duration: humanizeDuration(Date.parse(date) - Date.now(), { language: this.getDTLocale(), round: true, units: ['d', 'h'], conjunction: ' ' }) });
    }
  }
}
</script>

<style scoped>
.align-right {
  position: absolute;
  right: 1.25rem;
}
</style>